<template>
    <div>
        <div class="modal fade" :id="idModal" data-backdrop="static" role="dialog" aria-labelledby="staticBackdropLabel"
             aria-hidden="true" :style="{position: position + '!important'}">
            <div class="modal-dialog"
                 :class="{lg: modalLarge, 'extra-modal-large': modalExtraLarge, 'modal-xl': modalXl}" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="footerCenterBtnsModalLabel">
                            <slot v-if="titleModal" name="title"></slot>
                        </h5>
                        <button type="button" class="closeX" id="closeX" data-dismiss="modal" aria-label="Close"
                                @click="$emit('close')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {useI18n} from 'vue-i18n';

export default {
    setup() {
        const {t} = useI18n();
        return {t};
    },
    props: {
        titleModal: {
            type: String,
            default: '',
        },
        idModal: {
            type: String,
            default: 'customModalTwo',
        },
        modalLarge: {
            type: Boolean,
            default: false,
        },
        modalExtraLarge: {
            type: Boolean,
            default: false,
        },
        modalXl: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: 'fixed',
        },
    },
};
</script>
<style>
.lg {
    max-width: 800px !important;
}

.extra-modal-large {
    max-width: 95vw !important;
}
</style>
